import SectionLabel from 'components/Section'
import DepartmentHeath from "containers/Overview/Components/DepartmentHeath";
import { useLocation } from "react-router-dom";

const DepartmentHeathPdf = () => {

  const location = useLocation();
  const decode_and_parse: any = (key: string, defaultValue: string) => {
    const value = new URLSearchParams(location.search).get(key) || defaultValue;
    return JSON.parse(decodeURIComponent(value))
  }

  const departmental_health_overview = decode_and_parse("departmental_health_overview", "{}");
  const is_dependent = new URLSearchParams(location.search).get('is_dependent') == 'true';
  const program = decode_and_parse("program", "{}");
  const video_therapy_session_count = new URLSearchParams(location.search).get('video_therapy_session_count') || "0"

  return (
    // myiFrame id is needed to be able to print to the file (can refer to the lambda function)

    <>
      <main id="myiFrame">
        {
          !is_dependent && (
            <>
              <div className="CardVisualization mx-auto mt-10 px-4">
                <SectionLabel
                  tooltip={"Note: Only departments with more than 10 active users will be displayed"}
                  key={'SectionLabel-3'} title="Departmental Health" />
                <div className="flex flex-wrap justify-between space-x-8 mb-10">
                  <DepartmentHeath departmental_health_overview={departmental_health_overview} />
                </div>
              </div>

            </>
          )
        }
      </main>
    </>
  )
}

export default DepartmentHeathPdf;